module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HorseOfCourse","short_name":"HorseOfCourse","start_url":"/","background_color":"#ECFEFF","display":"minimal-ui","icon":"src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d7b09bbb7575d16c39ca8dd7f033a044"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
